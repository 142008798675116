/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://sksogonq6jcmhcmzaehh4t3ski.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-li7totmpgbeerd7hzkwzgzxnjm",
    "aws_cognito_identity_pool_id": "eu-west-1:a183aa0c-c2b2-4ae1-bcc2-3e634003e06e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_K8HymnQmi",
    "aws_user_pools_web_client_id": "ti53hbfk4te08maui36rmc8ma",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
